import scroll from 'scroll';
import defaults from 'lodash/defaults';
import { getOffset } from 'dom-lib';

const win = typeof window !== 'undefined' ? window : {};
const doc =
  typeof document !== 'undefined' ? document : { documentElement: {} };
// IE < 9 & Node
let scrollElem =
  typeof win.pageYOffset === 'undefined' ? doc.documentElement : null;

function detectScrollElem() {
  const startScrollTop = window.pageYOffset;
  document.documentElement.scrollTop = startScrollTop + 1;
  if (window.pageYOffset > startScrollTop) {
    document.documentElement.scrollTop = startScrollTop;
    // IE > 9 & FF (standard)
    return document.documentElement;
  }
  // Chrome (non-standard)
  return document.scrollingElement || document.body;
}

function scrollDoc() {
  return scrollElem || (scrollElem = detectScrollElem());
}

export default function scrollTo(options, cb) {
  const { id, x, y, container, ...rest } = defaults(options, {
    id: '',
    container: scrollDoc(),
  });
  const el = document.getElementById(id);
  const rect = el ? getOffset(el) : { left: 0, top: 0 };

  scroll.left(container, x || rect.left, rest, cb);
  scroll.top(container, y || rect.top, rest, cb);
}
