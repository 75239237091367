export function noop() {}

export function GetQueryValue(search, queryName) {
  if (!search) return null;

  const reg = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)', 'i');
  const r = search.substr(1).match(reg);

  return r != null ? decodeURI(r[2]) : null;
}
